<script setup>
import { computed } from "vue";
import { RouterLink } from "vue-router";
import { useI18n } from "vue-i18n";

const publicPath = process.env.BASE_URL;

const pathPrivacyPolicy = computed(() => {
    return `Personal_Data_Processing_and_Privacy_Policy_MOBIADS_${useI18n().locale.value}.pdf`;
});
</script>

<template>
  <footer class="footer">
    <div class="footer__in container">
      <div class="footer__left">
        <div class="footer__logo">
          <RouterLink to="/">
            <img
              src="@/assets/img/logo.svg"
              width="93"
              height="16"
              alt="MobiAds Logo"
            />
          </RouterLink>
        </div>

        <div class="footer__copy">
          Copyright 2024. MobiAds. All rights reserved
        </div>
      </div>
      <div class="footer__right">
        <div class="footer__contacts">
          <a class="footer__contacts-item" href="tel:+74991100739"
            >+7 499 110-07-39</a
          >
          <a class="footer__contacts-item" href="mailto:welcome@mobiads.io"
            >welcome@mobiads.io</a
          >
          <!--          <a class="footer__contacts-item" href="https://facebook.com/mobiadsgroup" rel="noreferrer noopener">Facebook.com/mobiadsgroup</a>-->
          <a
            class="footer__contacts-item"
            href="https://vk.com/mobi_ads"
            rel="noreferrer noopener"
            >VK.com/mobi_ads</a
          >
        </div>

        <div class="footer__law">
          <a
            class="footer__law-item"
            :href="`${publicPath}docs/${pathPrivacyPolicy}`"
            target="_blank"
          >
            {{ $t("footer.politics") }}
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
.footer {
  margin-top: auto;
  background-color: $color-footer-gray;
  font-size: 9px;
  text-align: center;

  @include s-sm {
    font-size: 15px;
  }

  @include s-lg {
    text-align: left;
  }

  &__in {
    padding-top: 24px;
    padding-bottom: 16px;

    @include s-lg {
      display: flex;
      padding-top: 55px;
      padding-bottom: 74px;
    }
  }

  &__left {
  }

  &__right {
    @include s-lg {
      margin-left: auto;
      text-align: right;
    }
  }

  &__logo {
    margin-bottom: 9px;
  }

  &__copy {
    margin-bottom: 16px;

    @include s-md {
      margin-bottom: 0;
    }
  }

  &__contacts {
    margin-bottom: 9px;
  }

  &__contacts-item {
    display: block;
    margin-bottom: 8px;
    transition: color 0.3s ease-in-out;

    @include s-lg {
      display: inline-block;
      margin-bottom: 0;
      margin-left: 16px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: $color-black;
    }
  }

  &__law-item {
    color: $color-text-light-gray;
    transition: color 0.3s ease-in-out;

    &::before {
      content: "|";
      display: inline-block;
      margin-right: 16px;
      margin-left: 16px;
    }

    &:hover {
      color: $color-text-gray;
    }

    &:first-child::before {
      content: none;
    }
  }
}
</style>
