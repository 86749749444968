<template>
  <div class="whywe">
    <h2 class="whywe__title title">{{ $t('whywe.title') }}</h2>
    <p class="whywe__text text">
      {{ $t('whywe.text') }}
    </p>
    <p class="whywe__contact">
      <a class="whywe__contact-us" href="#contacts">{{ $t('whywe.button') }}</a>
    </p>
    <div class="whywe__img">
      <img src="@/assets/img/waw.png" alt="img">
    </div>
  </div>  
</template>

<style lang="scss">
  .whywe {
    margin-bottom: 64px;
    border-radius: 16px;
    background-color: $color-footer-gray;
    padding-top: 32px;
    text-align: center;

    @include s-md {
      margin-bottom: 80px;
      border-radius: 20px;
    }

    @include s-lg {
      margin-bottom: 100px;
    }

    @include s-xl {
      margin-bottom: 216px;
      padding-top: 96px;
    }

    &__title {
      max-width: 544px;
      margin-right: auto;
      margin-left: auto;
      padding-right: 16px;
      padding-left: 16px;
    }

    &__text {
      max-width: 752px;
      margin-top: 4px;
      margin-right: auto;
      margin-left: auto;
      padding-right: 16px;
      padding-left: 16px;
  
      @include s-md {
        margin-top: 16px;
      }
  
      @include s-lg {
        margin-top: 24px;
      }
  
      @include s-xl {
        margin-top: 30px;
      }
    }

    &__contact {
      margin-top: 16px;

      @include s-lg {
        margin-top: 32px;
      }
    }

    &__contact-us {
      display: inline-flex;
      align-items: center;
      font-size: 15px;
      font-weight: 700;
      vertical-align: middle;

      &::after {
        content: '';
        display: inline-block;
        width: 20px;
        height: 14px;
        margin-left: 8px;
        background-repeat: no-repeat;
        background-image: url('@/assets/img/arrow-right.svg');
        transition: transform 0.25s ease-in-out;
      }

      &:hover::after {
        transform: translateX(3px);
      }
    }

    &__img {
      margin-top: 40px;

      @include s-lg {
        margin-top: 77px;
      }
    }
  }
</style>
