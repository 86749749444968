<script setup>
import IntroBlock from '@/components/IntroBlock.vue'
import ClientsScroll from '@/components/ClientsScroll.vue'
import SectionsList from '@/components/SectionsList.vue'
import WhyWe from '@/components/WhyWe.vue'

import {computed, reactive} from 'vue'
import {useHead} from "@vueuse/head";
import i18n from "@/plugins/i18n";

const siteData = reactive({
  title: i18n.global.t('metaHome.title'),
  description: i18n.global.t('metaHome.description'),
})

useHead({
  title: computed(() => siteData.title),
  meta: [
    {
      name: 'description',
      content: computed(() => siteData.description)
    },
    {
      name: 'robots',
      content: 'index, follow'
    }
  ],
  link: [
    {
      rel: 'canonical',
      href: window.location.href
    }
  ]
})

</script>

<template>

  <IntroBlock
      img="header-home.png"
  >
    <template #title>
      {{ $t('introHome.title') }}
    </template>

    <template #subtitle>
      {{ $t('introHome.subtitle') }}
    </template>

    <template #text>
      {{ $t('introHome.text') }}
    </template>
  </IntroBlock>

  <ClientsScroll/>

  <SectionsList/>

  <WhyWe/>
</template>
