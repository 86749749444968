<script setup>
import { ModalsContainer } from 'vue-final-modal'
import { computed } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import Header from '@/components/TheHeader.vue'
import Footer from '@/components/TheFooter.vue'
import ContactsBlock from '@/components/ContactsBlock.vue'
import BreadCrumbs from "@/components/BreadCrumbs"

const isNotCareerPage = computed(() => useRoute().name !== 'career');

// import {useMeta} from 'vue-meta'
// import {useI18n} from "vue-i18n";
// import {computed} from "vue";
//
// const { t } = useI18n();

// const siteTitle = computed(() => t('title'));
// const siteDescription = computed(() => t('description'));

// useMeta(computed(() => {
//   return {
//     title: siteTitle.value,
//     description: siteDescription.value,
//     twitter: {
//       card: {
//         content: 'website',
//       },
//       title: siteTitle.value,
//       image: {
//         content: 'https://mobiads.io/og-img.png',
//       },
//       description: siteDescription.value,
//       url: {
//         content: 'https://mobiads.io/',
//       }
//     },
//     og: {
//       type: {
//         content: 'website',
//       },
//       title: siteTitle.value,
//       image: {
//         content: 'https://mobiads.io/og-img.png',
//       },
//       description: siteDescription.value,
//       url: {
//         content: 'https://mobiads.io/',
//       }
//     }
//   }
// }))
</script>



<template>
  <metainfo/>
  <Header/>

  <teleport to="body">
    <component :is="'script'" type="application/ld+json">
      {
      "@context": "https://schema.org",
      "@type": "Organization",
      "email": "welcome@mobiads.io",
      "name": "MobiAds",
      "telephone": "+7 (499) 110-07-39",
      "url": "https://mobiads.io/en/"
      }
    </component>
  </teleport>

  <main class="main container">
    <BreadCrumbs v-if="isNotCareerPage" />
    <RouterView />
    <ContactsBlock v-if="isNotCareerPage" />
      <ModalsContainer />
  </main>
  <Footer/>
</template>


