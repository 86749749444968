<script setup>
import {Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/autoplay'
import {useRouter} from "vue-router";

const breakpointsOptions = {
  576: {
    slidesPerView: 4,
    spaceBetween: 32,
  },
  768: {
    slidesPerView: 5,
    spaceBetween: 40,
  },
  992: {
    slidesPerView: 6,
    spaceBetween: 48,
  },
  1280: {
    slidesPerView: 6,
    spaceBetween: 67,
  }
}

const autoplayOptions = {
  delay: 0,
  disableOnInteraction: false
}
const router = useRouter()

const clients = [
  {
    url: 'https://winline.ru/',
    logo: 'clients/winline.png'
  },
  {
    url: 'https://www.fon.bet/',
    logo: 'clients/fonbet.png'
  },
  {
    url: 'https://www.ligastavok.ru/',
    logo: 'clients/liga_stavok.png'
  },
  {
    url: 'https://betboom.ru/',
    logo: 'clients/betboom.png'
  },
  {
    url: 'https://belbet.by/',
    logo: 'clients/belbet.svg'
  },
  {
    url: 'https://delimobil.ru/',
    logo: 'clients/delimobil.svg'
  },
  {
    url: 'https://betcity.ru/',
    logo: 'clients/betcity.svg'
  },
  {
    url: 'https://www.tinkoff.ru/',
    logo: 'clients/tinkoff.svg'
  },
  {
    url: 'https://www.ivi.ru/',
    logo: 'clients/ivi.png'
  },
  {
    url: 'https://mostbet.ru/',
    logo: 'clients/mostbet.svg'
  },
  {
    url: 'https://www.stoloto.ru/',
    logo: 'clients/stoloto.png'
  },
  {
    url: 'https://sbermarket.ru/',
    logo: 'clients/sber_market.png'
  },
  {
    url: 'https://sbermegamarket.ru/',
    logo: 'clients/sber_megamarket.png'
  },
  {
    url: 'https://zvuk.com/',
    logo: 'clients/sberzvyk.svg'
  },
  {
    url: 'https://www.utkonos.ru/',
    logo: 'clients/ytkonos.svg'
  },
  {
    url: 'https://magnit.ru/',
    logo: 'clients/magnit.svg'
  },
  {
    url: 'https://www.perekrestok.ru/',
    logo: 'clients/perekrestok.svg'
  },
  {
    url: 'https://okko.tv/',
    logo: 'clients/okko.svg'
  },
  {
    url: 'https://leon.ru/',
    logo: 'clients/leon.png'
  },
  {
    url: 'https://dozarplati.com/',
    logo: 'clients/dozarplati.png'
  },
  {
    url: 'https://vkusvill.ru/',
    logo: 'clients/vkysvill.png'
  },
  {
    url: 'https://www.zaymer.ru/',
    logo: 'clients/zaimer.png'
  },
  {
    url: 'https://www.litres.ru/',
    logo: 'clients/litres.png'
  },
]
const clientsEn = [
  {
    url: 'https://delimobil.ru/',
    logo: 'clients/delimobil.svg'
  },
  {
    url: 'https://www.tinkoff.ru/',
    logo: 'clients/tinkoff.svg'
  },
  {
    url: 'https://www.ivi.ru/',
    logo: 'clients/ivi.png'
  },
  {
    url: 'https://www.stoloto.ru/',
    logo: 'clients/stoloto.png'
  },
  {
    url: 'https://www.utkonos.ru/',
    logo: 'clients/ytkonos.svg'
  },
  {
    url: 'https://magnit.ru/',
    logo: 'clients/magnit.svg'
  },
  {
    url: 'https://www.perekrestok.ru/',
    logo: 'clients/perekrestok.svg'
  },
  {
    url: 'https://okko.tv/',
    logo: 'clients/okko.svg'
  },
  {
    url: 'https://dozarplati.com/',
    logo: 'clients/dozarplati.png'
  },
  {
    url: 'https://vkusvill.ru/',
    logo: 'clients/vkysvill.png'
  },
  {
    url: 'https://www.zaymer.ru/',
    logo: 'clients/zaimer.png'
  },
  {
    url: 'https://www.litres.ru/',
    logo: 'clients/litres.png'
  },
]
</script>

<template>
  <swiper
      class="clients-scroll"
      :modules="[Autoplay]"
      :slides-per-view="3"
      :loop="true"
      :speed="3000"
      :space-between="16"
      :breakpoints="breakpointsOptions"
      :autoplay="autoplayOptions"
  >
    <swiper-slide
        v-for="(client, i) in router.currentRoute.value.path !== '/en' ? clients : clientsEn"
        :key="i"
        class="clients-scroll__item"
    >
      <a
          :href="client.url"
          target="_blank"
      >
        <img
            :src="require(`@/assets/img/${client.logo}`)"
            alt="logo"
        >
      </a>
    </swiper-slide>
  </swiper>
</template>

<style lang="scss">
.clients-scroll {
  margin-bottom: 32px;

  img {
    opacity: .5;
    filter: grayscale(100%);
  }

  @include s-md {
    margin-bottom: 64px;
  }

  @include s-lg {
    margin-bottom: 80px;
  }

  @include s-xl {
    margin-bottom: 104px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    z-index: 2;
    width: 32px;
    height: 100%;
    pointer-events: none;
  }

  &::before {
    left: 0;
    background-image: linear-gradient(to left, rgba($color-bgr, 0) 0, $color-bgr 100%);
  }

  &::after {
    right: 0;
    background-image: linear-gradient(to right, rgba($color-bgr, 0) 0, $color-bgr 100%);
  }

  &__item {
    text-align: center;
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
    transition-timing-function: linear;
  }
}
</style>
