<script setup>
import { useRoute } from 'vue-router'
import FeedbackForm from '@/components/FeedbackForm.vue'

import { computed, reactive } from 'vue'
import { useHead } from "@vueuse/head";
import i18n from "@/plugins/i18n";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const siteData = reactive({
  title: i18n.global.t('metaContacts.title'),
  description: i18n.global.t('metaContacts.description'),
})

useHead({
  title: computed(() => siteData.title),
  meta: [
    {
      name: 'description',
      content: computed(() => siteData.description)
    },
    {
      name: 'robots',
      content: 'index, follow'
    }
  ],
  link: [
    {
      rel: 'canonical',
      href: window.location.href
    }
  ]
})

const formInputs = [
  {
    name: 'contacts',
    label: computed(() => t('feedbackForm.label.contacts')),
    isValid: true,
  },
  {
    name: 'message',
    label: computed(() => t('feedbackForm.label.message')),
    isValid: true,
  }
];

const formCheckboxes = [
  {
    name: 'policyCheckbox',
    label: computed(() => t('feedbackForm.label.policyCheckbox')),
    isValid: true,
  },
  {
    name: 'newsLettersCheckbox',
    label: computed(() => t('feedbackForm.label.newsLettersCheckbox')),
    isValid: false,
  }
]
</script>

<template>
  <div class="contacts" id="contacts">
    <div class="contacts__body">
      <template v-if="useRoute().name === 'contacts'">
        <h1 class="contacts__title title">{{ $t('contactsBlock.title1') }}</h1>
        <h2 class="contacts__subtitle subtitle">{{ $t('contactsBlock.subtitle1') }}</h2>
      </template>
      <template v-else>
        <h2 class="contacts__title title">{{ $t('contactsBlock.title2') }}</h2>
        <h3 class="contacts__subtitle subtitle">{{ $t('contactsBlock.subtitle2') }}</h3>
      </template>
      <template v-if="useRoute().name === 'creative-lab'">
        <h1 class="contacts__title title">{{ $t('contactsBlock.title3') }}</h1>
      </template>

      <p class="contacts__text text">
        {{ $t('contactsBlock.text') }}
      </p>

      <div class="contacts__list">
        <div class="contacts__item">
          <div class="contacts__item-contact">
            <a href="tel:+74991100739">+7 499 110-07-39</a>
          </div>
          <div class="contacts__item-label">{{ $t('contactsBlock.phone') }}</div>
        </div>
        <div class="contacts__item">
          <div class="contacts__item-contact">
            <a href="mailto:welcome@mobiads.io">welcome@mobiads.io</a>
          </div>
          <div class="contacts__item-label">email</div>
        </div>
        <!--        <div class="contacts__item">-->
        <!--          <div class="contacts__item-contact">-->
        <!--            <a href="https://facebook.com/mobiadsgroup" rel="noreferrer noopener" target="_blank">mobiadsgroup</a>-->
        <!--          </div>-->
        <!--          <div class="contacts__item-label">facebook</div>-->
        <!--        </div>-->
        <div class="contacts__item">
          <div class="contacts__item-contact">
            <a href="https://vk.com/mobi_ads" rel="noreferrer noopener" target="_blank">mobi_ads</a>
          </div>
          <div class="contacts__item-label">{{ $t('contactsBlock.vk') }}</div>
        </div>
      </div>
    </div>
    <div class="contacts__form">
      <FeedbackForm
        :form-inputs="formInputs"
        :form-checkboxes="formCheckboxes"
        :button-text="t('feedbackForm.button')"
      />
    </div>
  </div>
</template>

<style lang="scss">
.contacts {
  @include s-sm {
    display: flex;
    flex-direction: column;
    margin-right: -16px;
    margin-left: -16px;

    @include s-lg {
      flex-direction: row;
    }
  }

  &__body {

    @include s-sm {
      padding-right: 16px;
      padding-left: 16px;
    }

    @include s-lg {
      width: 50%;
      flex: 0 0 auto;
    }
  }

  &__text {
    margin-top: 4px;

    @include s-md {
      margin-top: 16px;
    }

    @include s-lg {
      margin-top: 23px;
    }
  }

  &__list {
    margin-top: 22px;

    @include s-lg {
      margin-top: 56px;
    }
  }

  &__item {
    margin-top: 16px;
    font-size: 15px;
    line-height: 1.2;

    @include s-lg {
      margin-top: 35px;
      font-size: 28px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__item-contact {
    color: $color-black;
    font-weight: 700;
  }

  &__item-label {
    color: $color-text-light-gray;
    font-weight: 400;
  }

  &__form {
    margin-top: 32px;

    @include s-sm {
      padding-right: 16px;
      padding-left: 16px;
    }

    @include s-lg {
      width: 50%;
      flex: 0 0 auto;
      margin-top: 0;
    }
  }
}
</style>
