<template>
  <div class="dropdown" :class="{'dropdown__active': isOpen}" @click="openSwitcher">
    <button type="button">
      <img :src="require(`@/assets/img/${computedFlag}`)" alt="">
      {{ i18n.global.locale.value }}
      <img src="@/assets/img/chevron.svg" alt="chevron" class="dropdown__chevron">
    </button>
    <div class="dropdown__menu">
      <span v-for="(locale, i) in computedLangs" :key="`locale-${i}`" @click="switchLocale(locale.info)">
        <img :src="require(`@/assets/img/${locale.flag}`)" alt="">
       {{ locale.info }}
      </span>
    </div>
  </div>
</template>

<script setup>
import i18n from "@/plugins/i18n";
import router from "@/router";
import {computed, ref} from "vue";

const locales = (process.env.VUE_APP_I18N_SUPPORTED_LOCALE || '').split(',');
const flags = [{flag: 'gb.svg'}, {flag: 'ru.svg'}]
let isOpen = ref(false)

const langs = computed(
    () => locales.map((info, i) => ({info, ...flags[i]}))
)

const computedFlag = computed(
    () => langs.value.find(x => x.info === i18n.global.locale.value).flag
)

const computedLangs = computed(
    () => langs.value.filter(x => x.info !== i18n.global.locale.value)
)

const openSwitcher = () => {
  isOpen.value = !isOpen.value
}

const switchLocale = (locale) => {

  if (i18n.global.locale.value !== locale) {
    let currentPathObject = router.currentRoute.value;
    i18n.global.locale.value = locale;
    router.push({name: currentPathObject.name, params: {locale: i18n.global.locale.value}});
    openSwitcher()

    setTimeout(() => location.reload(), 1)
  }
}
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 10px 6px 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  color: #000000;
  cursor: pointer;

  @include s-lg {
    &:hover {
      .dropdown__menu {
        visibility: visible;
        opacity: 1;
        display: flex;
      }
    }
  }

  > button {
    display: flex;
    align-items: center;
    text-transform: capitalize;

    > img {
      margin-right: 3px;
    }
  }

  &__chevron {
    margin: 0 0 0 6px;
  }

  &__active {
    .dropdown__menu {
      visibility: visible;
      opacity: 1;
      display: flex;
    }
  }

  &__menu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    display: none;
    flex-direction: column;
    top: 106%;
    left: 8px;
    padding: 7px 10px 6px 8px;
    background: $gradient-violet;
    border-radius: 0px 0px 6px 6px;
    color: black;
    transition: all 0.5s ease;

    @include s-md {
      top: 75%;
    }

    > span {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      > img {
        margin-right: 3px;
      }
    }
  }

}
</style>
