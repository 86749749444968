export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile marketing agency"])},
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "cases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cases"])},
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "creative-lab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds Creative Lab"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancies"])}
  },
  "introHome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile marketing agency"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We help businesses find new customers"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We apply our 10 years of market expertise in every project entrusted to us, and develop and implement a comprehensive strategy for you to attract new users."])}
  },
  "introCases": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our cases"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real projects with great results"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our mission is to help your business grow using the full power of mobile devices and modern advertising technologies."])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Clients"])}
  },
  "introSolutions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media buying"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right decisions for the best results"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We work only with high-quality traffic sources that bring the maximum benefit to our clients."])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our sources"])}
  },
  "introServices": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Application Promotion"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We cover the full range of your mobile app marketing needs, from strategy development to conversion optimization for each banner."])}
  },
  "sectionList": {
    "section1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds In-App Network"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds In-App Network works with the largest advertising platforms and game publishers. We have generated over 1 million leads and secured over 500 million registrations in a year."])}
    },
    "section2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media buying"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our 150 media buyers and in-house technology optimise traffic in a way that guarantees quality users and a solid return on investment over a short period of time."])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Information"])}
  },
  "whywe": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why should you choose us?"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds is an international project and has been in the Performance Marketing industry for 10 years. We are leading the way in the development and promotion of gaming and non-gaming mobile applications such as e-comm, video streaming, food delivery, sports and utilities."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
  },
  "contactsBlock": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’d love to hear from you!"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to know more?"])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us!"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you’d like more information about us and our work, kindly fill out the form to contact us, or feel free to reach out in a way that is convenient for you.ƒ"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you’d like more information about us and our work, kindly fill out the form to contact us, or feel free to reach out in a way that is convenient for you."])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
    "vk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VKontakte"])}
  },
  "feedbackForm": {
    "errorField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty field"])},
    "errorMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email"])},
    "label": {
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your contact (email, skype, telegram, etc)"])},
      "skype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Skype"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
      "policyCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I consent to the processing of my personal data for marketing purposes in accordance with the privacy policy"])},
      "newsLettersCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to receive newsletters from MobiAds"])}
    },
    "isMessageSended": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have received your application and we will contact you soon!"])}
      },
      "fail": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For technical reasons, your message could not be delivered."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again later."])}
      }
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a message"])}
  },
  "footer": {
    "politics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])}
  },
  "cases": {
    "okko": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKKO"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An online cinema with more than 90,000 films, cartoons, series, sports programs and broadcasts."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrations"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trial/year"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CR"])}
      }
    },
    "delimobil": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delimobil"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Russian short-term car rental service launched in 2015. Delimobil operates in a number of Russian cities and has a fleet of more than 15,000 vehicles."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrations"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trips"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reach"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All cities where Delimobil is present in the territory of the Russian Federation"])}
      }
    },
    "winline": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winline"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A dynamically developing Russian bookmaker which appeared on the betting market in 2009. In such a short period of time Winline bookmaker has come a long way in its development and gained fame in the betting market of the CIS countries."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrations"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first deposits"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["second deposits"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["presence on YouTube, sports category"])}
      }
    },
    "stoloto": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stoloto"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stoloto has over 25,000 points of sale in Russia. Lottery products distributed by Stoloto Trading House are sold in all regions of Russia. Stoloto is organised by the Ministry of Finance of the Russian Federation and the Ministry of Sports of the Russian Federation."])},
      "info": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first purchases"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus/Single Channel TikTok Campaign"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effie Awards 2021"])}
      }
    }
  },
  "ourstat": {
    "name1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["traffic sources"])},
    "name2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["installations per year"])},
    "name3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countries"])}
  },
  "process": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
    "step1": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user sees an ad for the app in one of our sources"])}
    },
    "step2": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicks on the ad and is taken to the marketplace page"])}
    },
    "step3": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads <br>the application"])}
    },
    "step4": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opens the app and registers"])}
    }
  },
  "benefits": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Benefits"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUTOMATION"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traffic optimization technologies allow you to get the maximum return on investment."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREATIVITY"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The creative department uses the appropriate and most effective approaches to attract and hook your target audience."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANTI-FRAUD"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our own anti-fraud system protects our clients from fraud and low-quality traffic."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUALITY AND VOLUME"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With 10 years of experience and expertise under our belt, we are well-versed in the most effective ways to generate large volumes of high-quality traffic in a short amount of time."])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMER SERVICE"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We take a transparent approach to our work, provide weekly reports, and assign a dedicated manager to each of our clients, all of which guarantee our clients the highest level of service."])}
  },
  "performance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Performance Marketing"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The basis of our work is performance marketing: we promote brand applications while presenting our clients with accurate results, clear data, KPI achievements, and Return on Investment (ROI), all within a short period of time."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance marketing often comes with an advertising budget of over 200,000 rubles. However, even with more modest funding, performance marketing can still be used to run basic but necessary tools such as tracking what funding is required for a particular channel or viewing statistics on the effectiveness of tools."])}
  },
  "performanceHelp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With performance marketing you can:"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measure the results of operated channels in advertising campaigns"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluate business indicators: Cost Per Acquisition (CPA), and Return on Investment (ROI)"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control promotion results in real time"])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronize the activity going on in all promotion channels (SEO, SMM, email newsletters, media advertising)"])}
  },
  "performanceAso": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASO (App Store Optimization)"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASO or App Store Optimization is a comprehensive process of optimizing a mobile application page in the App Store and Google Play stores in order to maximize its visibility, downloads, and conversion of visitors. ASO includes full text optimization of the application’s name and description, as well as graphics."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASO forms the foundation of app promotion. The more detailed and thorough ASO is, the better the results of future advertising campaigns. ASO solves the main business tasks of:<br>— increasing the application’s visibility;<br>— increasing conversion;<br>— rating improvement."])}
  },
  "performanceAsoBenefits": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How will ASO benefit you?"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword indexing: Your app is easily found in app store searches with various keywords."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High organic install rates and steady growth: All ASO tools have a direct impact on organic traffic."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less exposure to changes in store conditions: each app store has its own characteristics which affect the stability of ASO indicators."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visual elements which result in good conversions: the user easily identifies your application in a competitive field and receives basic information faster."])}
  },
  "creativeLab": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobi<span>Ads</span> Creative Lab"])},
    "info1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creatives determine 50% of ad campaign success"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothesis testing allows you to increase engagement"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our extensive experience in the market distinguishes us from competitors"])}
    },
    "info2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources to back your ideas (large team of designers, latest tools and training)"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High volume capacity which means that campaigns are constantly running without interruption"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Static, banners, 2D video, 3D video, playable ads & UGC ads, etc."])}
    }
  },
  "metaHome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds - Expert Mobile Marketing Solutions for Your Business"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds: Expert mobile marketing for reaching goals ✅ Tech-driven, targeting your audience effectively"])}
  },
  "metaCases": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds - Successful Case Studies in Mobile Marketing"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds: Empowering growth via mobile. OKKO, Delimobil, Stoloto - our success stories speak"])}
  },
  "metaSolutions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprehensive Mobile Advertising Agency- MobiAds"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovative mobile marketing by MobiAds ✅ High-quality traffic, anti-fraud systems, top-tier services"])}
  },
  "metaServices": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Comprehensive App Marketing and ASO Services"]), _normalize(["MobiAds"])])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds: ASO specialists for App Store & Google Play ✅ Maximize ROI with our full-range services"])}
  },
  "metaCreativeLab": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds Creative Lab - Design and Production for Successful Ad Campaigns"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MobiAds Creative Lab: Your ad success partner ✅ From static to 3D, we create engaging formats"])}
  },
  "metaContacts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact MobiAds - Your Mobile Marketing Partner"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact MobiAds for tech-based mobile marketing ✅ Grow your business now! Call +7 499 110-07-39 or visit welcome-mobiads.io"])}
  },
  "careerPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancies"])},
    "text": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How's this?"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe that trust in our partners and teams starts with ourselves."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The success of teams and the company, and strong relationships between colleagues, partners and clients ride on employees» self-confidence and self-respect. We encourage our employees to be confident in their abilities and experience and provide them with the necessary tools to help them develop as professionals and individuals."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today, our goal is to become the best-performing mobile marketing agency in the world."])}
    ],
    "badges": {
      "badge1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional growth opportunities"])},
      "badge2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mental health support incentives"])},
      "badge3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team building activities"])},
      "badge4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language learning discounts"])},
      "badge5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible working hours"])},
      "badge6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wellness compensation packages"])},
      "badge7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate events"])}
    },
    "vacancyTitles": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsibilities:"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirements:"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What We Offer:"])}
    ],
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a response"])},
    "form": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach out to us!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What to join or haven’t found your stream? Share your CV with us here! We promise we will get in touch."])},
      "label": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "policyCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree with <a href=\"https://mobiads.io/docs/Personal_Data_Processing_and_Privacy_Policy_MOBIADS_en.pdf\">terms of use</a>"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
      }
    }
  },
  "vacancies": [
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Sales Manager (Digital)"])},
      "info": {
        "responsibilities": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seek out new advertisers and negotiate terms for long-term partnerships."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engage in business development: advocate for the agency in new negotiations and defend proposals with existing clients."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect feedback, fostering extensive communication, and take a proactive approach."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participate in digital industry conferences."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurture and enhance productive, long-standing relationships with both new and existing clients."])}
        ],
        "requirements": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience in B2B sales, managing significant budgets, and cultivating relationships with key clients."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprehensive grasp of the mobile advertising industry and familiarity with major market stakeholders."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum 2 years experience in a similar role preferably within an agency or the digital field."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client-oriented mindset and an entrepreneurial spirit."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent communication and collaboration skills."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practical mindset and great teamwork skills."])}
        ],
        "offer": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible working hours schedule and a hybrid work format: 5/2 workweek (5 days on, 2 days off) and additional days off."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitive and stable salary."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comfortable offices in Moscow with meals, regular events, and other bonuses, including a relaxation room with PS5, table tennis, massage chairs."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic environment with open communication no bureaucracy or formalities."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A professional international team: collaborating with global-level experts in their respective fields, regular engagement in international conferences, and access to professional development opportunities."])}
        ]
      }
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Account Manager"])},
      "info": {
        "responsibilities": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cultivate enduring relationships and assist clients in acquiring mobile traffic."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conduct up-selling activities."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage projects and coordinate team members."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Develop and implement media plans, providing regular reports."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversee and manage accounts."])}
        ],
        "requirements": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one year of experience in Digital/Mobile/CPA within an agency or CPA network."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprehensive understanding of the specifics of the mobile marketing market."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposure to various industry verticals."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profound expertise and experience with models such as CPI, CPA, LTV, and RevShare."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proficiency in online tools including Excel, Google Sheets, Facebook Business Manager, AppsFlyer, Adjust, and Affise."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An analytical mindset, attentiveness, honesty, quick thinking, and advanced communication skills."])}
        ],
        "offer": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible working hours schedule and a hybrid work format: 5/2 workweek (5 days on, 2 days off) with flexible start of the day,  and additional days off weekends."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitive and stable salary."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comfortable offices in Moscow with full meals, regular events, and other bonuses, including a relaxation room with PS5, table tennis, massage chairs."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic environment with open communication no bureaucracy or formalities."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A professional international team: collaborating with global-level experts in their respective fields, regular engagement in international conferences, and access to professional development opportunities."])}
        ]
      }
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junior Account Manager"])},
      "info": {
        "responsibilities": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervise the progress of advertising campaigns under the guidance of a mentor."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compile routine internal reports and analytics for clients."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create invoices and finalize documents for advertisers."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepare required documentation for banks."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adhere strictly to work instructions, guidelines, and protocols."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate all workflow procedures with a mentor."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensure the timely completion of assigned tasks."])}
        ],
        "requirements": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced proficiency in MS Excel."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strong analytical skills."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familiarity with key advertising metrics: CPC, CPA, LTV, RevShare, etc."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermediate level of English (preferred)."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ability to promptly respond to complex situations, demonstrate resourcefulness, creativity, responsibility, and stress tolerance."])}
        ],
        "offer": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible working hours schedule and a hybrid work format: 5/2 workweek (5 days on, 2 days off) with flexible start of the day,  and additional days off weekends."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitive and stable salary."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comfortable offices in Moscow with full meals, regular events, and other bonuses, including a relaxation room with PS5, table tennis, massage chairs."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic environment with open communication no bureaucracy or formalities."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A professional international team: collaborating with global-level experts in their respective fields, regular engagement in international conferences, and access to professional development opportunities."])}
        ]
      }
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Programmatic Manager"])},
      "info": {
        "responsibilities": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establish communication with traffic source representatives to enhance project technical performance."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget control, monitoring conversions and traffic quality, and making continuous efforts to improve performance indicators."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitor CMS operation, developing recommendations for optimizing advertising campaigns in terms of CPA, ROI."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate the media buying department's work with new traffic sources."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuous interaction with related departments: accounts, sales, analytics, finance."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prompt technical debugging of application settings, creating technical specifications and ensuring timely resolution of technical issues."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate ideas and prepare presentations for partner platforms."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compile regular reports."])}
        ],
        "requirements": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Higher technical education."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience in Digital, Mobile, CPA within an agency or CPA network."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience with mobile trackers: AppsFlyer, Appmetrica, Adjust, etc."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understanding of key metrics for analyzing CPI, CPA, ROI, LTV effectiveness."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge of the mobile traffic procurement process and its metrics, and an understanding of social media ad platforms and In-App networks operation."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience with traffic sources: Google Ads, TikTok, Huawei Ads, Xiaomi, Facebook, Unity, IronSource, In-App, etc."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strong proficiency in Excel, Power BI."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytical mindset."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English language proficiency - Upper-Intermediate."])}
        ],
        "offer": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible working hours schedule and a hybrid work format: 5/2 workweek (5 days on, 2 days off) with flexible start of the day,  and additional days off weekends."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitive and stable salary."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comfortable offices in Moscow with full meals, regular events, and other bonuses, including a relaxation room with PS5, table tennis, massage chairs."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic environment with open communication no bureaucracy or formalities."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A professional international team: collaborating with global-level experts in their respective fields, regular engagement in international conferences, and access to professional development opportunities."])}
        ]
      }
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Director (Digital)"])},
      "info": {
        "responsibilities": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proactively seek out new advertisers and cultivate enduring partnerships."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engage in business development, advocating for the agency's interests in new deals and adeptly defending proposals with existing clients."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engage with clients, nurturing communication, initiating negotiations, adopting a proactive stance, and consistently gathering feedback."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participate in conferences, representing the company at industry events, and expanding the network of contacts."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage and enhance departmental operations: coordinating and optimizing workflows to deliver optimal outcomes."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foster client relationships: sustaining productive and long-standing partnerships with both new and existing clients."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conduct analytics and identify growth opportunities in the agency's B2B sales."])}
        ],
        "requirements": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Higher technical education."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience in Digital, Mobile, CPA within an agency or CPA network."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience with mobile trackers: AppsFlyer, Appmetrica, Adjust, etc."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understanding of key metrics for analyzing CPI, CPA, ROI, LTV effectiveness."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge of the mobile traffic procurement process and its metrics, and an understanding of social media ad platforms and In-App networks operation."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience with traffic sources: Google Ads, TikTok, Huawei Ads, Xiaomi, Facebook, Unity, IronSource, In-App, etc."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strong proficiency in Excel, Power BI."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytical mindset."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English language proficiency - Upper-Intermediate."])}
        ],
        "offer": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensive experience in B2B sales: substantial track record of collaborating with major clients and managing significantly large budgets."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proficiency in the mobile advertising market, with a comprehensive understanding of its present landscape and key stakeholders."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proficient in devising sales strategies: demonstrated experience in developing and implementing effective processes."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience as a sales department manager in an agency and the digital field."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client-centric mindset, adept at understanding client requirements, and possessing an entrepreneurial drive."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding communication abilities."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership attributes, pragmatism, and the ability to collaborate effectively within a team."])}
        ]
      }
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Executive Recruiter"])},
      "info": {
        "responsibilities": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruitment of top-level personnel;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scout for rare talents;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluate soft skills and create descriptions of required competencies for the hiring manager;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conduct market analysis, competitor analysis, and detailed mapping;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participate in brainstorming sessions, practicing unconventional approaches to establishing cold contact with candidates;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work on the overall candidate database and develop your personal network;"])}
        ],
        "requirements": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 3 years of experience in recruitment;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience in searching for C-level specialists;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience in international recruitment;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience in scouting for rare specialists;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highly developed communication and organizational skills, ability to structure your time and set priorities;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proactivity, ability to apply various methods of candidate search in the market depending on the position."])}
        ],
        "offer": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesting work with opportunities for professional and career growth;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We cover all effective tools for recruitment;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitive salary (fixed + KPI for each hired employee);"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work schedule 5/2 with flexible hours at the start of the working day, and 28 vacation days;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A young and friendly team of like-minded people around the world;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office lunches, tea, coffee, sweets, fresh fruits, corporate events, and team-building activities;"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate discounts for learning foreign languages."])}
        ]
      }
    }
  ]
}