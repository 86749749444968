<script setup>
import {computed} from "vue";
import {useI18n} from "vue-i18n";
// eslint-disable-next-line
const props = defineProps({
  isMessageFail: {
    type: Boolean,
    default: false
  }
})
const {t} = useI18n()
const content = computed(() => {
  const info = {
    title: '',
    text: ''
  }

  if (props.isMessageFail) {
    info.title = t('feedbackForm.isMessageSended.fail.title')
    info.text = t('feedbackForm.isMessageSended.fail.text')
  } else {
    info.title = t('feedbackForm.isMessageSended.success.title')
    info.text = t('feedbackForm.isMessageSended.success.text')
  }
  return info
})

</script>
<template>
  <div class="sent" :class="{'sent__fail': isMessageFail}">
    <img :src="require(`@/assets/img/${isMessageFail ? 'robot.png' : 'notes.png'}`)" alt="">
    <div class="sent__info">
      <h2 class="title sent__title-fail"
          :class="{'sent__title-fail': isMessageFail}"
      >
        {{ content.title }}
      </h2>
      <p class="sent__text-fail"
         :class="{'sent__text-fail': isMessageFail}"
      >
        {{ content.text }}
      </p>
    </div>
  </div>
</template>

<style lang="scss">
.sent {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 24px 20px;
  z-index: 1;
  color: $color-black;
  text-align: center;
  background-color: $color-white;
  border: 3px solid $color-lighter-green;
  border-radius: 16px;

  @include s-lg {
    padding: 64px 40px;
  }

  &__fail {
    padding: 24px 20px;
    border: 3px solid $color-lighter-pink;

    @include s-lg {
      padding: 44px 40px;
    }
  }

  > img {
    margin-bottom: 40px;
  }

  &__info {
    > h2 {
      margin-bottom: 24px;
    }

    > p {
      font-weight: 400;
      font-size: 22px;
      line-height: 1.25;
      color: $color-text-light-gray;

      @include s-lg {
        font-size: 32px;
      }
    }
  }

  &__title-fail {
    font-size: 22px;

    @include s-lg {
      font-size: 32px;
    }
  }

  &__text-fail {
    font-size: 32px;

    @include s-lg {
      font-size: 42px;
    }
  }
}
</style>