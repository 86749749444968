<template>
  <div class="breadcrumbs">
    <ul class="breadcrumbs__list" itemscope="" itemtype="https://schema.org/BreadcrumbList">
      <li class="breadcrumbs__item" v-for="(crumb, index) in breadcrumbs" :key="index" itemprop="itemListElement"
          itemscope="" itemtype="https://schema.org/ListItem">
        <span itemprop="item" v-if="$route.path === crumb.to">{{ crumb.label }}</span>
        <router-link v-else :to="crumb.to" class="breadcrumbs__link" itemprop="item">
          <span itemprop="name">{{ crumb.label }}</span>
        </router-link>
        <meta itemprop="position" :content="index">
      </li>
    </ul>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "BreadCrumbs",
  computed: {
    breadcrumbs() {
      const crumbs = [
        {label: i18n.global.t("nav.home"), to: '/'},
      ]

      const matchedRoutes = this.$route.matched

      for (const match of matchedRoutes) {

        if (match.meta.breadcrumb) {
          const breadcrumbLabel = typeof match.meta.breadcrumb === 'function' ? match.meta.breadcrumb(match) : match.meta.breadcrumb
          const breadcrumbLabel2 = typeof match.meta.title === 'function' ? match.meta.title(match) : match.meta.title
          crumbs.push({
            label: i18n.global.locale.value === 'ru' ? breadcrumbLabel2 : breadcrumbLabel,
            to: match.path.replace('/:locale', `${this.$route.path.substring(0, 3)}`)
          })
        }
      }

      return crumbs
    }
  }
}
</script>

<style lang="scss">
.breadcrumbs {
  &__list {
    display: flex;
  }

  &__item {
    position: relative;
    margin-right: 6px;
    padding-right: 12px;
    &:not(:last-child)::after {
      content: '/';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      color: #AFAFB1;
    }
  }

  &__link, span {
    color: #AFAFB1;
  }

}
</style>
